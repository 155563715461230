:root{

    --color-2: hsl(218deg 34.88% 16.86%); 

    --color-3: #dd2e44; 
    --color-4: #ffdbda;
    --color-5: #85AFFB;
    --color-6: #a5b4cf;
    --color-7: #baf7d9;


    --white-color: #fff;
}

