@import './vars';


@media screen and (max-width: 1440px) {
    .tomato_header_menu a{
        font-size: 20px;
    }
    .tomato_header_menu{
        column-gap: 30px;
    }

}


@media screen and (max-width: 1024px) {
    #tomato_search__form{
        margin-bottom: 25px;
    }
    #tomato_search__form:active, #tomato_search__form:focus, #tomato_search__form:hover{
        width: 100%;
    }

    .tomato_Wrapper{
        margin-right: 0;
        border-right: none;
        padding-right: 0;
    }
    .tomato_Wrapper{
        width: 100%;
    }
    .tomato_Sidebar{
    display: none;
    flex-direction: column;
    width: 320px;
    padding: 15px;
    position: absolute;
    z-index: 200000;
    left: 0;
    top: 144px;
    padding-top: 40px;
    border-radius: 0;
    border-top: 2px solid var(--color-4);
    }
    .tomato_header_inner{
        display: grid;
    }
    .tomato_header_menu{
        grid-column-start: 1;
        grid-column-end: 3;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
    } 
    .tomato_mobile__button{
        display: flex;
        justify-self: end;
    }
    .tomato_mute-box__muteUnmute{
        display: none;
    }
    .tomato_header_menu a{
        margin-top: 20px;
    }
    .tomato_mute-box{
        display: none;
    }
    .tomato_letter__link{
        background: var(--color-3);
    }
}

@media screen and (max-width: 992px) {
    .tomato_melodious__list a{
        font-size: 24px;
    }
    .tomato_track-info{
        margin-right: 60px;
    }
    }
@media screen and (max-width: 920px) {
}

@media screen and (max-width: 768px) {
    .ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__badge, .ya-share2__container.ya-share2__container_color-scheme_blackwhite .ya-share2__mobile-popup-badge{
        background-color: var(--color-2) !important;
        outline: 2px solid var(--color-2) !important;
    }
    .tomato_Social{
        display: none;
        &--1{
            display: flex;
        }
      }
      
    .tomato_card_like{
        display: none;
    }
    #tomato_search__form{
        width: 100%;
        margin-bottom: 20px;
    }
    .tomato_Popular_title{
        font-size: 25px;
    }
    .tomato_player-title_heading{
        font-size: 17px;
        margin-right: 0;
    }
    .tomato_track__leftWrapper{
        justify-self: end;
    }
    .tomato_player-title{
        justify-content: center;
        margin-top: 15px;
        order: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .tomato_track-central{
        margin-right: 0;
    }
    .tomato_results-info__card{
        padding: 15px 10px;
    }
    .tomato_melodious__list{
        flex-direction: row;
    }
    .tomato_melodious__list a{
        margin-right: 5px;
        margin-left: 5px;
    }
    .tomato_boxline__bottom{
        text-align: center;
    }
    .tomato_mute-box{
        margin-right: 30px;
    }
    .tomato_about{
        flex-direction: column;
    }
    .tomato_logo-link{
        display: none;
    }
    .tomato_wrapper{
        margin-left: 0;
    }

    .tomato_mobile-nav__button{
        width: min-content;
        justify-self: end;
    }
    .tomato_song-aplhabet{
        width: auto;
    }
    .tomato_popular_title{
        font-size: 25px;
    }
    .tomato_popular_logo{
        width: 40px;
        height: 40px;
    }
    .tomato_copyright_box-mail{
        font-size: 24px;
    }
    .tomato_sidebar{
        display: none;
    }
    .tomato_wrapper{
        width: 100%;
    }
    .tomato_melodious__list a::before{
        display: none;
    }
    .tomato_footer_copy{
        width: 100%;
    }
}

@media screen and (max-width: 670px) {
    .tomato_header_menu a:hover{
        margin-left: 30px;
    }
    .tomato_Sidebar{
        padding-top: 210px;
        top: 100px;
    }
    .tomato_header_inner{
        display: flex;
    }
    .tomato_header_menu{
        display: none;
        position: absolute;
        left: 30px;
        top: 110px;
        flex-direction: column;
        z-index: 9999999;
        width: 320px;
        justify-content: center;
    }
    .tomato_melodious__list a{
        justify-content: center;
    }
}

@media screen and (max-width: 624px) {
    .tomato_story_heading{
        margin-left: 40px;
    }
    .tomato_music__title,.tomato_story_heading, .tomato_genre-item,.tomato_story_track-link{
        justify-content: center;
    }

    
    
    
    .tomato_copyright_box-mail a{
        margin-left: 0;
    }
    .track{
        flex-direction: column;
    }
    .tomato_copyright_box{
        margin-bottom: 220px;
    }
    .social-box{
        right: 30px;
    }

    .tomato_about{
        margin-bottom: 190px;
    }
    .tomato_track-central{
        margin-right: 0;
    }
    .tomato_track-info{
        margin-bottom: 10px;
    }
    .tomato_mute-box {
        width: auto;
        justify-self: center;
        grid-column-start: 2;
        margin-right: 0;
    }
    .tomato_time{
        margin-right: 15px;
    }
    .tomato_zero-time{
        margin-left: 0;
        font-size: 16px;
    }
    .tomato_time_space{
        font-size: 16px;
    }
    .tomato_timeBox{
        margin-right: 0;
        font-size: 16px;
    }
    .tomato_copyright_box-mail{
        flex-direction: column;
    }
}



@media screen and (max-width: 577px) {

    .tomato_popular_logo{
        display: none;
    }
    .tomato_popular_title{
        text-align: center;
    }
    .tomato_sotial-network-vk_btn{
        width: 100%;
        margin-bottom: 20px;
    }
    .tomato_sotial-network-vk_link{
        width: 100%;
    }
    .tomato_sotial-network-vk_input{
        flex-direction: column;
    }


    .tomato_download_vk{
        width: 100%;
    }
    .tomato_vk{
        width: 100%;
        min-width: auto;
    }
    .tomato_social-box{
        padding-bottom: 0;
    }
    .tomato_player-title_heading{
        margin-right: 0;
    }
    .tomato_results-info__card_download{
        width: 40px;
        height: 40px;
    }
}    


@media screen and (max-width: 510px) {
    .tomato_copyright_box{
        margin-top: 0;
    }
    .tomato_genre-item_link, .tomato_story_track-link{
        font-size: 15px;
        padding: 12px;
    }
    .tomato_mobile__button-line{
        width: 40px;
    }
    .tomato_card{
        padding: 10px;
    }
    .title{
        width: calc(100% - 50px);
    }
    .submit{
        width: 55px;
    }
    .tomato_card_download{
        width: 40px;
        height: 40px;
    }
    .tomato_card .start{
        transform: scale(0.6);
    }
    .tomato_card_time{
        display: none;
    }
    .tomato_card_download svg{
        width: 40px;
        height: 40px;
    }
    .tomato_header{
        margin-bottom: 25px;
        padding: 20px 0;
    }
    .tomato_track-info{
        margin-right: 0;
    }
    .tomato_player-title{
        margin-top: 0;
    }
    .tomato_copyright_box-mail{
        font-size: 20px;
        margin-top: 10px;
        text-align: center;
    }
    .tomato_player-title_heading{
        text-align: center;
        margin-bottom: 10px;
    }
    .progress-bar__container{
        flex-direction: column;
    }
    .tomato_Social_vk{
        display: none;
    }
    .res-card_st-bf_results-info__card-subtext, .tomato_subinfo__time{
        display: none;
    }
    .tomato_player-title{
        text-align: center;
        margin-left: 0;
        justify-content: center;
        margin-bottom: 0;
    }
    .ltomato_description{
        margin-bottom: 20px;
    }
    .track{
        display: flex;
        flex-direction: column;
    }
    .tomato_mute-box{
        display: none;
    }
    .tomato_track-info{
        flex-direction: column;
        align-items: center;
    }

}

@media screen and (max-width: 470px) {
    .tomato_track-central_left-btn svg, .tomato_track-central_right-btn svg{
        width: 30px;
        height: 30px;
    }
    .tomato_track-central_left-btn svg, .tomato_track-central_right-btn svg{
        width: 40px;
        height: 40px;
    }
    .play::before, .pause::before{
        width: 40px;
        height: 40px;
    }


    
}

@media screen and (max-width: 425px) {
    .tomato_header_menu{
        top: 85px;
    }
    .tomato_Sidebar{
        top: 80px;
    }
    .ya-share2__container_mobile.ya-share2__container_size_l .ya-share2__icon{
        width: 50px !important;
        height: 50px !important;
    }
    
    .tomato_description_title{
        font-size: 15px;
    }
    .tomato_card_artist, .tomato_card_tracklink{
        -webkit-line-clamp: 2;
        line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .tomato_header_menu{
        flex-direction: column;
        margin-right: 40px;
    }
    
    
    .title{
        font-size: 14px;
    }
    .tomato_card{
        padding: 10px 5px 5px 5px;
    }
    
    
    .tomato_card_download{
        position: static;
    }
    .tomato_card-subtext{
        display: none;
    }
    .res-card_st-bf_results-info__card{
        padding: 15px 10px;
    }
    .tomato_popular__box{
        justify-content: center;
    }
    .tomato_melodious__heading{
        text-align: center;
    }
    .tomato_description_title{
        text-align: center;
    }
    .tomato_subinfo__time{
        display: none;
    }
    .tomato_results-info__card_time{
        margin-right: 0;
    }
    .tomato_results-info__card-subtext{
        margin-right: 10px;
        margin-left: 15px;
    }
    .tomato_results-info__card_artist, .tomato_results-info__card_space, .tomato_results-info__card_tracklink{
        font-size: 14px;
    }
    .tomato_results-info__card_like{
        display: none;
    }
    
    .tomato_results-info__card_download svg{
        width: 30px;
        height: 30px;
    }
    .tomato_results-info__card.pausing a.tomato_results-info__card_link::before {
        content: url(../images/musicTheme-play.svg);
        padding-left: 12px;
        padding-right: 8px;
        height: 22px;
    }
    .tomato_results-info__card_link::before{
        width: 20px;
        height: 20px;
        padding: 11px;
    }
    .tomato_results-info__card .start{
        width: 40px;
        height: 40px;
    }
    .tomato_vk::before{
        min-width: calc(300px + 6px);
    }
    .tomato_melodious__list{
        display: flex;
        flex-direction: column;
    }
    .tomato_melodious__list a{
        font-size: 30px;
    }
    .tomato_header_logo-link{
        font-size: 24px;
    }
    .tomato_header_logo-logo{
        width: 180px;
    }
}

@media screen and (max-width: 375px) {
} 